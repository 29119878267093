import Vue from "vue";
import VueLodash from "vue-lodash";
import findIndex from "lodash/findIndex";
import chunk from "lodash/chunk";
import filter from "lodash/filter";
import sumBy from "lodash/sumBy";
import remove from "lodash/remove";

Vue.use(VueLodash, {
  name: "customLodash",
  lodash: {
    findIndex: findIndex,
    chunk: chunk,
    filter: filter,
    sumBy: sumBy,
    remove: remove
  }
});
